@import '@helix-design-system/tokens/dist/styles/light.css';

/**
 * Allows content to grow between the current header and footer
 * TODO: Remove this once we create the universal header and footer
 **/
body div[id="single-spa-application:patientHubAccountMfe/activateConfig"],
body div[id="single-spa-application:patientHubAccountMfe/loginConfig"],
body div[id="single-spa-application:patientHubAccountMfe/passwordResetConfig"],
body div[id="single-spa-application:patientHubAccountMfe/profileConfig"],
body div[id="single-spa-application:patientHubAccountMfe/notfoundConfig"],
body div[id="single-spa-application:patientHubAccountMfe/supportConfig"],
body div[id="single-spa-application:patientHubAccountMfe/gatedConfig"],
body div[id="single-spa-application:patientHubProductMfe/dashboardConfig"],
body div[id="single-spa-application:patientHubProductMfe/testsConfig"],
body div[id="single-spa-application:patientHubKitreturnMfe/kitReturnApp"] {
  height: 100%;
}

.enable-vert-scroll {
  height: 100%;
  overflow-y: auto;
}